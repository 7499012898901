export default function ComboBox({
  options,
  title,
  labelText,
  handleChange,
  value,
  id,
  cname,
}) {
  return (
    <>
      {labelText && <label>{labelText}</label>}

      <select id={id} className={cname} onChange={handleChange} value={value}>
        <option selected>{title}</option>

        {options.map((option) => (
          <option id={option.id} key={option.id}>
            {option.label}
          </option>
        ))}
      </select>
    </>
  );
}
